import type {LinksFunction} from '@remix-run/cloudflare';

import PageLayout from '@/components/plus/redesign/page/PageLayout';
import Section from '@/components/base/layouts/Section/Section';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import Stats from '@/components/plus/modules/Stats/Stats';
import Testimonial from '@/components/base/sections/Testimonial/Testimonial';
import HeroFloatingImage from '@/components/base/sections/Hero/HeroFloatingImage';
import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import Checklist from '@/components/plus/section/Checklist/Checklist';
import SideBySideChecklist from '@/components/plus/section/SideBySideChecklist/SideBySideChecklist';
import FullWidthCard from '@/components/base/modules/FullWidthCard/FullWidthCard';
import GlobeWithStats from '@/components/plus/section/GlobeWithStats/GlobeWithStats';
import {ColorSchemeEnum} from '@/enums';
import {currency, number} from '@/utils/i18n';
import type {LogoDefType} from '@/utils/merchants';
import {getAllTestimonials, lookupMerchantLogo} from '@/utils/merchants';
import {useSiteData} from '@/hooks/useSiteData';
import {useTranslations} from '@/hooks/useTranslations';
import {isPublishedOnSite} from '@/utils/plusSite';
import {handle as parentHandle} from '@/components/plus/shared/handle';
import type {Handle} from '@/types';
import brochureV2Stylesheet from '@/stylesheets/brochureV2/brochureV2.css?url';
import plusRedesignStylesheet from '@/stylesheets/plus/redesign/plus.css?url';
import {usePlusNavLimitPages} from '@/hooks/experiments/usePlusNavLimitPages';

export {combinedLeadAction as action} from '@/utils/server/actions/plus/combinedLeadAction.server';
export {loader} from '@/utils/server/loader/loader';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: brochureV2Stylesheet,
  },
  {
    rel: 'stylesheet',
    href: plusRedesignStylesheet,
  },
];

export const handle: Handle = {
  ...parentHandle,
};

export default function PlusMigration() {
  const {t, settings} = useTranslations();
  const {site} = useSiteData();
  const testimonials = getAllTestimonials(t, settings, site);
  const testimonial1 = testimonials[0].map((testimonial) => ({
    ...testimonial,
    link: site.locale.startsWith('en')
      ? {
          text: t('quote.cta'),
          href: settings('quote.link'),
        }
      : undefined,
  }));
  const isShopPayAvailable =
    site.features.includes('payments_online') &&
    isPublishedOnSite('plus/solutions/payments', site);

  const logoDefs = settings('logos', {
    context: site.locale,
  }) as LogoDefType[];
  const logos = logoDefs.map((logoDef) => lookupMerchantLogo(t, logoDef));

  const contactText = t('plus:cta.contact');

  const {cs} = usePlusNavLimitPages();

  return (
    <PageLayout>
      <div className="bg-black relative -mt-plus-header-with-border">
        <div className="z-0 absolute w-full h-full bg-upgrade-page-hero" />
        <HeroFloatingImage
          headingGroupProps={{
            kicker: t('hero.eyebrow'),
            headingHtml: t('hero.heading'),
            subheadHtml: t('hero.text'),
          }}
          colorScheme={ColorSchemeEnum.Transparent}
          mode="dark"
          images={[{src: settings('hero.thumbnail', {context: site.locale})}]}
          ctas={{
            buttons: [
              {
                text: contactText,
                href: '#contact-sales',
                componentName: 'contact-sales',
              },
            ],
          }}
          className={`pt-2xl z-10 bg-transparent ${cs('pt-5xl md:pt-4xl')}`}
        />
      </div>

      <LogoGroup logos={logos} className="justify-evenly" />

      <Section className="lg:gap-y-0">
        <GlobeWithStats
          headingGroup={{
            kicker: t('scale.eyebrow'),
            headingHtml: t('scale.heading'),
          }}
          statsCards={[
            {
              metric: currency(t, 'scale.metric1', {
                currency: site.locale === 'en-IN' ? 'INR' : 'USD',
              }),
              description: t('scale.metric1.label'),
            },
            {
              metric: currency(t, 'scale.metric2', {
                minimumSignificantDigits: 2,
                currency: site.locale === 'en-IN' ? 'INR' : 'USD',
              }),
              description: t('scale.metric2.label'),
            },
            {
              metric: number(t, 'scale.metric3'),
              description: t('scale.metric3.label'),
            },
            {
              metric: number(t, 'scale.metric4', {
                minimumSignificantDigits: 2,
              }),
              description: t('scale.metric4.label'),
            },
            {
              metric: number(t, 'scale.metric5', {
                notation: 'standard',
              }),
              description: t('scale.metric5.label'),
            },
            {
              metric: number(t, 'scale.metric6'),
              description: t('scale.metric6.label'),
            },
          ]}
          customerReview={{
            title: t('customerReviews.heading'),
            url: settings('customerReviews.url'),
            urlText: t('customerReviews.linkText'),
            image: {
              src: settings('customerReviews.image', {
                context: site.locale,
              }),
              alt: t('customerReviews.image.alt'),
            },
          }}
        />
      </Section>

      <Section>
        <Testimonial {...testimonial1[0]} />
      </Section>

      <Section mode="dark" className="bg-black relative">
        <div className="z-0 absolute w-full h-full bg-plus-radial-gradient-right" />
        <div className="z-10">
          <TwoColumn>
            <TwoColumn.Col1 withGutter>
              <HeadingGroup
                kicker={t('conversion.eyebrow')}
                headingHtml={t('conversion.heading')}
                subheadHtml={t('conversion.text')}
                mode="dark"
              />
            </TwoColumn.Col1>
            <TwoColumn.Col2>
              <Stats
                stats={[
                  {
                    metric: number(t, 'conversion.stat1', {style: 'percent'}),
                    description: t('conversion.stat1.label'),
                  },
                  ...(isShopPayAvailable
                    ? [
                        {
                          metric: number(t, 'conversion.stat2', {
                            style: 'percent',
                          }),
                          description: t('conversion.stat2.label'),
                        },
                      ]
                    : []),
                ]}
                mode="dark"
              />
              <Stats
                stats={[
                  {
                    metric: number(t, 'conversion.stat3', {style: 'percent'}),
                    description: t('conversion.stat3.label'),
                  },
                  {
                    metric: number(t, 'conversion.stat4'),
                    description: t('conversion.stat4.label'),
                  },
                ]}
                mode="dark"
              />
            </TwoColumn.Col2>
          </TwoColumn>
        </div>
      </Section>

      <Section>
        <SideBySide
          image={{
            src: settings('flexibility.image'),
          }}
        >
          <HeadingGroup
            kicker={t('flexibility.eyebrow')}
            headingHtml={t('flexibility.heading')}
          />
          <Checklist itemsHtml={t('flexibility.checklist')} />
        </SideBySide>
      </Section>

      {site.locale === 'de' ? (
        <Section>
          <Testimonial {...testimonials[1][0]} />
        </Section>
      ) : null}

      <Section className="bg-shade-10">
        <SideBySideChecklist
          headingGroup={{
            kicker: t('speed.eyebrow'),
            headingHtml: t('speed.heading'),
            subheadHtml: t('speed.text'),
          }}
          itemsHtml={Object.values(t('speed.checklist'))}
        />
      </Section>

      <Section className="bg-black relative" mode="dark">
        <div className="z-0 absolute w-full h-full bg-plus-radial-gradient-left" />
        <Testimonial {...testimonials[2][0]} mode="dark" className="z-10" />
      </Section>

      <Section>
        <FullWidthCard
          image={{
            src: settings('integration.image'),
            className: 'relative z-10',
          }}
          visualPosition="right"
          className="bg-black relative"
          mode="dark"
        >
          <div className="z-0 top-0 left-0 absolute w-full h-full bg-plus-radial-gradient-bottom-left" />
          <HeadingGroup
            kicker={t('integration.eyebrow')}
            headingHtml={t('integration.heading')}
            subheadHtml={t('integration.text')}
            mode="dark"
            className="z-10"
          />
        </FullWidthCard>
      </Section>

      {site.locale === 'de' ? (
        <Section>
          <Testimonial {...testimonials[3][0]} />
        </Section>
      ) : null}

      <Section className="bg-shade-10">
        <SideBySide
          image={{
            src: settings('partners.image'),
          }}
        >
          <HeadingGroup
            kicker={t('partners.eyebrow')}
            headingHtml={t('partners.heading')}
          />
          <Checklist itemsHtml={t('partners.checklist')} />
        </SideBySide>
      </Section>

      {site.locale === 'de' ? (
        <Section className="bg-shade-10">
          <Testimonial {...testimonials[4][0]} />
        </Section>
      ) : null}

      <Section className="bg-black relative">
        <div className="z-0 absolute w-full h-full bg-plus-radial-gradient-bottom-left" />
        <Conversion
          mode="dark"
          headingHtml={t('cta.heading')}
          buttons={[
            {
              text: contactText,
              href: '#contact-sales',
              componentName: 'contact-sales',
            },
          ]}
          className="z-10"
        />
      </Section>
    </PageLayout>
  );
}
